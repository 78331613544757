import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Styled from "./style";

export default function Modal(props) {
  const { display, onClose, modalId } = props;
  const modalRef = useRef(null);
  const location = useLocation();
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    telefone: "",
    crm: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [redirectMessage, setRedirectMessage] = useState("");

  const validate = () => {
    const newErrors = {};

    if (!/^[A-Za-zÀ-ÿ\s]{2,}$/.test(formData.nome)) {
      newErrors.nome = "Digite seu nome completo.";
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Digite um e-mail válido.";
    }
    if (!/^\d{10,11}$/.test(formData.telefone)) {
      newErrors.telefone = "Digite um telefone válido com DDD.";
    }
    if (!/^\d{4,6}\/[A-Za-z]{2}$/.test(formData.crm)) {
      newErrors.crm = "Digite o CRM no formato correto (123456/SP).";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validate()) {
      console.error("Validação falhou", errors);
      return;
    }

    setIsSubmitting(true);
    setRedirectMessage("Redirecionando você para o contato...");

    const data = {
      nome: formData.nome,
      email: formData.email,
      telefone: formData.telefone,
      crm: formData.crm,
      origem: "Google",
      observacoes: "CURSO DE BLEFARO.",
    };

    try {
      const response = await fetch("https://api.pipefy.com/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJQaXBlZnkiLCJpYXQiOjE3MjUzODg0NzUsImp0aSI6Ijc0YTYyYTJiLTg4NzEtNDZiNy05MmRiLTdmNWMxMDUxYmE5OCIsInN1YiI6MzAzMjEzNDM3LCJ1c2VyIjp7ImlkIjozMDMyMTM0MzcsImVtYWlsIjoidGlhZ29hbG1laWRhc2FudG9zMDRAZ21haWwuY29tIn19.jJdEiAbINcjf0YmaNJMumP-B5iUaaff_EA8XgESCP-WSFEyyJmGgseOG_victBzPPlcO2vKv9o9O9JNn1mPNng`,
        },
        body: JSON.stringify({
          query: `
          mutation CreateCard($pipe_id: ID!, $phase_id: ID!, $fields: [FieldValueInput!]!) {
            createCard(input: {
              pipe_id: $pipe_id,
              phase_id: $phase_id,
              fields_attributes: $fields
            }) {
              card {
                id
              }
            }
          }
        `,
          variables: {
            pipe_id: 304487644,
            phase_id: "327107253",
            fields: [
              { field_id: "nome", field_value: data.nome },
              { field_id: "email", field_value: data.email },
              { field_id: "telefone", field_value: data.telefone },
              { field_id: "m_dico_1", field_value: data.crm },
              { field_id: "origem", field_value: data.origem },
              { field_id: "observa_es", field_value: data.observacoes },
            ],
          },
        }),
      });

      const result = await response.json();
      if (result.errors) {
        throw new Error(`Erro no Pipefy: ${JSON.stringify(result.errors)}`);
      }

      console.log("Card criado:", result.data.createCard.card.id);

      const whatsappLink = `https://api.whatsapp.com/send?phone=5511952443023&text=Ol%C3%A1,%20tenho%20interesse%20em%20realizar%20o%20Curso%20de%20blefaroplastia...`;
      window.location.href = whatsappLink;
      onClose();
    } catch (error) {
      console.error("Erro ao enviar dados:", error);
      alert("Houve um erro ao enviar seus dados. Por favor, tente novamente.");
    } finally {
      setIsSubmitting(false);
      setRedirectMessage("");
    }
  };

  return (
    <Styled.Container style={{ display: display ? "flex" : "none" }}>
      {redirectMessage && (
        <div className="redirectMessage">{redirectMessage}</div>
      )}
      <form ref={modalRef} onSubmit={handleSubmit} className="modalForm">
        <h3>Preencha o formulário e fale com um consultor pelo Whatsapp!</h3>

        <div className="inputGroup">
          <label htmlFor="nome">Nome</label>
          <input
            type="text"
            id="nome"
            name="nome"
            value={formData.nome}
            onChange={handleChange}
            placeholder="Insira seu nome completo"
          />
          {errors.nome && <p className="error">{errors.nome}</p>}
        </div>

        <div className="inputGroup">
          <label htmlFor="email">E-mail</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Insira seu melhor e-mail"
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>

        <div className="inputGroup">
          <label htmlFor="telefone">Telefone</label>
          <input
            type="tel"
            id="telefone"
            name="telefone"
            value={formData.telefone}
            onChange={handleChange}
            placeholder="Insira seu telefone com DDD"
          />
          {errors.telefone && <p className="error">{errors.telefone}</p>}
        </div>

        <div className="inputGroup">
          <label htmlFor="crm">CRM</label>
          <input
            type="text"
            id="crm"
            name="crm"
            value={formData.crm}
            onChange={handleChange}
            placeholder="Número + Sigla do Estado"
          />
          {errors.crm && <p className="error">{errors.crm}</p>}
        </div>

        <button className="btNCTA" type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Enviando..." : "Enviar"}
        </button>
        <button type="button" onClick={onClose} className="closeButton">
          X
        </button>
      </form>
    </Styled.Container>
  );
}
